
















































































































































































































import Vue from "vue";
import store from "../../store/shop";
import _ from "lodash";
import moment from "moment";
import { Effect } from "../../model/menu";
import ShopAPI from "../../common/api/api_shop";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import notificationMixin from "../../mixin/notification";
import { SessionStatus } from "../../model/entity/tableSessionSetting";

const api = new ShopAPI();
const ModalPopup = () => import("../elements/QModal.vue");
let popupConfirm;
let current_session_id = null;
let current_table_index = null;
let current_seat_index = null;
let current_session_data = null;
let move_table_index = null;

const translator = TranslateServiceBuilder.Instance()._;

export default Vue.component("SHTableSessionCard", {
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    SHChangePeoplePopup: () => import("../compositions/SHChangePeoplePopup.vue"),
  },
  mixins: [notificationMixin],
  data() {
    return {
      displayPopup:  false,
      displayPopupQrcode: false,
      displayQrcode: false,
      isOpenPopupChangePeople: false,
      seat_id_move_to: null,
      tableSessionSelected: {
        id: null
      },
      quantities: {
        grow: 0,
        child: 0,
      },
      qrCode: "",
      displayTitle: "",
      isLoading: false,
      isChangePeople: false,
    };
  },
  props: {
    table: {
      type: Object,
      required: true,
    },
    isSelfType: {
      type: Boolean,
      required: true,
    },
    table_index: null
  },
  mounted() {
    api.set_token(store.state.token);
  },
  computed: {
    effectStyle: () => (effect: Effect) => {
      let className = {};
      if (!effect.limit_option) {
        className = {
          TimeStarted: true,
        };
      } else if (!_.isNull(effect.start_time)) {
        const lastTime = moment(effect.last_order_time);
        const currentTime = moment();
        const remainTime = lastTime.diff(currentTime, "minutes");
        if (currentTime.isAfter(lastTime)) {
          className = {
            TimeExceeded: true,
          };
        } else if (remainTime < 10) {
          // before 10 minutes
          className = {
            TimeBeforeTenMinute: true,
          };
        } else {
          className = {
            TimeStarted: true,
          };
        }
      }
      return className;
    },
    isStart: () => (effect: Effect) => {
      if (!effect.limit_option) {
        return true;
      }
      return !_.isNull(effect.start_time);
    },
    effectLimitTitle: () => (effect: Effect) => {
      if (effect.end_time) {
        const endTime = moment(effect.end_time).format("HH:mm");
        const lastTime = moment(effect.last_order_time).format("HH:mm");
        return `${endTime} (L.O. ${lastTime})`;
      } else {
        return "--:-- (L.O. --:--)";
      }
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    moveListTables() {
      let listTables = [];
      _.forEach(store.state.tableGroups, function (tableGroup) {
        let table_seats = [];
        let isEmpty = false;
        let temp = _.cloneDeep(tableGroup);
        _.forEach(tableGroup.table_seats, function (table_seat) {
          if (!table_seat.table_sessions.length) {
            table_seats.push(table_seat)
            isEmpty = true;
          }
        });

        if (isEmpty) {
          temp.table_seats = table_seats;
          listTables.push(temp)
        }
      });

      return listTables;
    },
    disableButton() {
      return !this.seat_id_move_to || this.isLoading;
    },
    canUseEatIn(): boolean {
      return store.getters.canUseEatIn;
    },
    showQrCode() {
      return "//chart.apis.google.com/chart?chs=547x547&cht=qr&chl=" + this.qrCode;
    },
  },
  filters: {
    isActive: function (table) {
      if (table.active === false) {
        return "disabled";
      }
    },
    tableStatusLabel(seat): string {
        if (seat == undefined) {
            return '';
        }
        const session = seat.table_sessions[0];
      switch(session.status) {
          case SessionStatus.STATUS_PAYING:
              return 'paying';
      }

      return '';
    },
  },
  methods: {
    handleClickReturn: function () {
      this.isChangePeople = false;
      this.displayPopupQrcode = false;
    },
    startTimeLimit(effect: Effect) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      api
        .start_limit_time({ table_session_effect_id: effect.id })
        .then((response) => {
          this.isLoading = false;
          store.dispatch("fetchTableSessions");
          return;
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.showErrorNotification(err.response.data.message);
          this.isLoading = false;
        });
    },
    startAttachedMenu(effect: Effect) {
      // logic display title: {menu_name} ({cat_name1}, {cat_name2}, …)
      const sessionEffectName = _.chain(this.sessionEffect)
        .filter((item) => item.order_id === effect.order_id)
        .map((item) => item.category_option.title)
        .value();
      const popTitle = `${effect.order.menu_name}`;
      const popMessage = translator(
        "hall-0222",
        "制限時間を開始してください。"
      );

      ModalPopup().then((Popup) => {
        if (popupConfirm) {
          popupConfirm.remove();
          popupConfirm = null;
        }
        popupConfirm = new Popup.default({
          propsData: {
            parent: this.$parent.$el,
            isOpen: true,
            title: popTitle,
            message: popMessage,
            okMessage: translator("hall-0148", "開始"),
            isShowNg: true,
            ngMessage: translator("hall-0172", "キャンセル"),
            status: "info",
            okClass: "Green",
          },
        });
        popupConfirm.$on("ok", (opt) => {
          //   console.log('press ok');
          // send request update limit time
          this.startTimeLimit(effect);
        });
        popupConfirm.$mount();
      });
    },
    getTableSessionId(seat) {
      if (seat.table_sessions.length) {
        return seat.table_sessions[0].id;
      }

      return null;
    },
    formatTime(time) {
      if (time) {
        return moment(time).format("HH:mm");
      }

      return null;
    },
    displayQuantity(number) {
      if (number) {
        return number + '名様';
      }

      return null;
    },
    displayChild(number) {
        return '(' + (number ? number : 0) + '名様)';
    },
    effects(table_session_id) {
      let sessionEffect = _.find(
        store.state.tableSessions,
        (table) => table.id === table_session_id
      );

      if (!sessionEffect) {
        return null;
      }


      let effets = _.uniqBy(sessionEffect.table_session_effects, "order_id");

      if (effets.length) {
        return effets;
      }

      return null;
    },
    setSessionChangeFrom(id, seat_index, table_index, table_session) {
      current_table_index = table_index;
      current_seat_index = seat_index;
      current_session_id = id;
      current_session_data = table_session;
    },
    setSeatChangeTo(event, id, table_index) {
      move_table_index = table_index;
      this.seat_id_move_to = event.target.checked ? id : null;
    },
    isCheck(id) {
      return id == this.seat_id_move_to;
    },
    changeSeat() {
      this.isLoading = true;
      let message = '';
      let data = {
        session_id: current_session_id,
        seat_id_move_to: this.seat_id_move_to,
      };

      api
        .move_seat(data)
        .then((response) => {
          if (response.data.status !== "success") {
            message = translator("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }

          message = response.data.message;
          this.showSuccessNotification(message);
          let tables = _.cloneDeep(store.state.tableGroups);
          _.map(tables[move_table_index].table_seats, (table_seat) => {
            if (table_seat.id == this.seat_id_move_to) {
              table_seat.table_sessions = current_session_data;
            }
          });

          tables[current_table_index].table_seats[current_seat_index].table_sessions = [];
          store.dispatch("setTableGroups", tables);

          this.displayPopup = false;
          this.isLoading = false;
          this.seat_id_move_to = null;
          this.showSuccessNotification(translator("hall-0241", "座席の移動に成功しました。"));

          this.displayPopupQrcode = true;
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translator("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
    qrReissue() {
      this.isLoading = true;
      let message = '';
      let data = {
        table_session_id: current_session_id,
      };

      api
        .qr_reissue(data)
        .then((response) => {
          if (response.data.status !== "success") {
            message = translator("hall-0256", "QRコードの再発行に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.displayPopupQrcode = false;
          this.isLoading = false;
          this.current_session_id = null;
          this.isChangePeople = false;
          this.showSuccessNotification(translator("hall-0255", "QRコードを再発行しました。"));
        })
        .catch((err) => {
          message = translator("hall-0256", "QRコードの再発行に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
          this.isChangePeople = false;
        });
    },
    getQrCode(table_session_id, name) {
      this.loading = true;
      let message = '';
      let data = {
        table_session_id: table_session_id,
      };
      api.qrcode(data)
        .then((response) => {
          if (response.data.status === "success") {
            this.qrCode = response.data.qrcode;
            this.displayTitle = name;
            this.displayQrcode = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          message = translator("hall-0048", "データの取得に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
    ChangePeople: function (table_session) {
      if (table_session) {
        this.tableSessionSelected = table_session;
        this.quantities.grow = table_session.quantities - table_session.quantity_child;
        this.quantities.child = table_session.quantity_child;
        current_session_id = table_session.id;
        this.isOpenPopupChangePeople = true;
      }
    },
    changedPeople: function() {
      this.closePopup();
      this.isLoading = false;
      store.dispatch("fetchTableSessions");
    },
    closePopup: function () {
      this.isOpenPopupChangePeople = false;
      this.isChangePeople = false;
    },
    closeSuccessPopup: function () {
      this.isChangePeople = true;
      this.displayPopupQrcode = true;
    },
    closeQrCode: function () {
      this.displayQrcode = false;
    },
  },
});
